
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";

export default defineComponent({
  name: "account-overview",
  components: {},
  setup() {
    const store = useStore();

    onMounted(() => {
      setCurrentPageBreadcrumbs("Coming Soon", ["Coming Soon"]);
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // computed(() => {
    // // eslint-disable-next-line vue/no-async-in-computed-properties
    // store.dispatch(Actions.GETACCOUNT).then(function (data) {
    //   return data;
    // });
    // return true;
    // });
  },
});
